<script>
import { apiGetGames, apiCheerUp } from "@/http";
import { mixinableInfiniteLoad, mixinablePagination } from "@/mixins";

import imageNoticeBoardBackground from "@/assets/images/encourage/notice_board_background.png";
import imageFloatIcon from "@/assets/images/encourage/float_icon.png";
import imageAssist from "@/assets/images/encourage/assist.png";
import imageAssisted from "@/assets/images/encourage/assisted.png";
import imageEliminated from "@/assets/images/encourage/eliminated.png";
import imageTagPersonal from "@/assets/images/encourage/tag_personal.png";
import imageTagLeader from "@/assets/images/encourage/tag_leader.png";

import InternalPagesLayout from "@/layouts/InternalPagesLayout.vue";
import Empty from "@/components/Empty.vue";
import ImperativeDialog from "@/components/ImperativeDialog.vue";

export default {
  mixins: [mixinableInfiniteLoad, mixinablePagination],
  components: {
    InternalPagesLayout,
    Empty,
    ImperativeDialog,
  },
  data() {
    return {
      imageNoticeBoardBackground,
      imageFloatIcon,
      imageAssist,
      imageAssisted,
      imageEliminated,
      imageTagPersonal,
      imageTagLeader,

      games: [],
      assistedTeams: [],
      active: -1,
      assistErrorMessage: "",
      disableRefresh: false,
      assistTarget: "",

      // mixin
      infiniteLoadApi: "/ann_api/h5/team/getTeam",
      infiniteLoadTriggerDistance: 126,

      // mixin
      pagination: {
        size: 20,
      },
    };
  },
  methods: {
    getGames() {
      apiGetGames().then((res) => {
        this.games = res.data;
        this.active = res.data[0].id;

        this.$nextTick(() => {
          this.infiniteLoadReload();
        });
      });
    },

    refresh(done) {
      this.pagination.page = 0;
      this.pagination.total = 0;
      this.pagination.items = [];

      this.$nextTick(() => {
        this.infiniteLoadReload()?.then(() => {
          done();
        });
      });
    },

    handleActive(id) {
      this.active = id;
      this.pagination.page = 0;
      this.pagination.total = 0;
      this.pagination.items = [];

      this.$nextTick(() => {
        this.infiniteLoadReload();
      });
    },

    handleNavigateToRaffle() {
      this.$router.push("/choujiang/raffle");
    },

    handleNavigateToPointsHistory() {
      this.$router.push("/choujiang/me/history/points");
    },

    handleAssist(item) {
      if (this.assistedTeams.includes(item.id) || item.status === 1) {
        return;
      }

      this.assistTarget = item.name;

      this.dialogConfirmAssist?.open((context) => {
        context.close();

        apiCheerUp({ team_id: item.id }).then((res) => {
          if (res.errorCode !== 1000) {
            this.assistErrorMessage = res.errorMessage;
            this.dialogRemainingCheerLimit?.open((context) => {
              context.close();
            });

            return;
          }

          if (res.data === -1) {
            this.assistErrorMessage =
              "今天助威次数不足哦～ 每天每人可助威4次！";
            this.dialogRemainingCheerLimit?.open((context) => {
              context.close();
            });
          } else if (res.data === 0) {
            this.dialogPointsIncrease10?.open((context) => {
              context.close();
              this.handleNavigateToPointsHistory();
            });
          } else {
            this.dialogPointsIncrease10?.open((context) => {
              context.close();
              this.handleNavigateToPointsHistory();
            });

            this.dialogPointsIncrease100?.open((context) => {
              context.close();
              this.handleNavigateToPointsHistory();
            });
          }
        });
      });
    },

    // mixin
    infiniteLoadProcess(res) {
      const items = res.data.data.data;
      const total = res.data.data.total;
      const page = res.data.data.current_page;
      const assisteds = res.data.team.map((id) => Number.parseInt(id, 10));

      this.pagination.items.push(...items);
      this.pagination.total = total;
      this.pagination.page = page;
      this.assistedTeams.push(...assisteds);
    },

    // mixin
    paginationChangeHandler() {
      console.log(this.pagination.page, this.pagination.size);
    },

    handleNoticeBoardTouchEvent(e) {
      if (e.type === "touchstart") {
        this.disableRefresh = true;
      } else {
        this.disableRefresh = false;
      }
    },
  },
  computed: {
    activeGame() {
      return this.games.find((item) => item.id === this.active);
    },
    activeGamePicture() {
      return this.activeGame?.img || "";
    },

    dialogConfirmAssist() {
      return this.$refs.dialogConfirmAssist;
    },
    dialogRemainingCheerLimit() {
      return this.$refs.dialogRemainingCheerLimit;
    },
    dialogPointsIncrease10() {
      return this.$refs.dialogPointsIncrease10;
    },
    dialogPointsIncrease100() {
      return this.$refs.dialogPointsIncrease100;
    },

    // mixin
    infiniteLoadEnable() {
      if (this.active < 0) {
        return false;
      }

      if (
        this.pagination.items.length >= this.pagination.total &&
        this.pagination.items.length !== 0
      ) {
        return false;
      }

      return true;
    },

    // mixin
    infiniteLoadParams() {
      return {
        game_id: this.active,
        page: this.pagination.page + 1,
        page_er: this.pagination.size,
      };
    },
  },

  beforeMount() {
    this.getGames();
  },
};
</script>

<template>
  <div>
    <InternalPagesLayout :refresh="disableRefresh ? undefined : refresh">
      <div class="page">
        <div class="notice_board">
          <div class="background">
            <img :src="imageNoticeBoardBackground" class="background_image" />
          </div>
          <div class="content" @touchstart="handleNoticeBoardTouchEvent" @touchend="handleNoticeBoardTouchEvent">
            <div class="title">助威活动规则说明</div>
            <ol class="rules">
              <li>
                每人每天(每周一至周五)可助威4次，每次助威成功，助威人可获得10积分，被助威参赛队伍/参赛者可获得100威力值。 7月8日-7月26日每人每周助威满20次，可额外获得100积分全勤奖励。
              </li>
              <li>
                每周五15:30-16:30抽奖区限时开启，可点击活动页右侧的抽奖浮窗进入，每100积分可抽奖一次，每周奖品有限，先到先得。
              </li>
              <li>积分记录与中奖记录可在"我的"页面查看。</li>
            </ol>
          </div>
        </div>

        <div class="tab">
          <div v-for="item in games" :class="['tab_item', active === item.id && 'tab_item__active']" :key="item.id"
            @click="handleActive(item.id)">
            {{ item.name }}
            <div class="line" />
          </div>
        </div>

        <div class="total">
          共计<span class="count"> {{ pagination.total }} </span>个选手参与排名
        </div>

        <div class="items">
          <div class="item" v-for="item in pagination.items" :key="item.id">
            <img class="item_picture" :src="activeGamePicture" />

            <div class="item_info">
              <div class="item_header">
                <div class="item_name">{{ item.name }}</div>
                <div class="item_current_encourage_value">
                  当前威力值:{{ item.sign_integrate }}
                </div>
              </div>

              <div class="item_footer">
                <div class="item_disseminate">
                  {{ item.team_members?.[0]?.name }}

                  <img class="item_team_tag" :src="item.team_type === 2 ? imageTagPersonal : imageTagLeader
                    " />
                </div>

                <div class="assist_button" @click="handleAssist(item)">
                  <img v-if="item.status === 1" :src="imageEliminated" />
                  <img v-else :src="assistedTeams.includes(item.id)
                    ? imageAssisted
                    : imageAssist
                    " />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="loading" v-if="infiniteLoadPending">正在加载</div>

        <div class="nodata" v-if="!infiniteLoadPending && pagination.items.length === 0">
          <Empty />
        </div>
      </div>
    </InternalPagesLayout>

    <div class="raffle_button" @click="handleNavigateToRaffle">
      <img :src="imageFloatIcon" />
    </div>

    <ImperativeDialog ref="dialogConfirmAssist" confirmLabel="确认">
      <div class="dialog-body">
        <div class="message" style="margin-top: 10px">
          是否确认给【{{ assistTarget }}】助威？
        </div>
      </div>
    </ImperativeDialog>
    <ImperativeDialog ref="dialogRemainingCheerLimit" hideCancelButton confirmLabel="知道了">
      <div class="dialog-body">
        <div class="message" style="margin-top: 10px">
          {{ assistErrorMessage }}
        </div>
      </div>
    </ImperativeDialog>

    <ImperativeDialog ref="dialogPointsIncrease10" confirmLabel="积分记录" cancelLabel="关闭">
      <div class="dialog-body">
        <div class="title" style="margin-top: 10px">
          积分 <span class="hightlight">+10</span>
        </div>
        <div class="tips" style="margin-top: 25px">可到我的积分记录查询</div>
      </div>
    </ImperativeDialog>
    <ImperativeDialog ref="dialogPointsIncrease100" confirmLabel="积分记录" cancelLabel="关闭">
      <div class="dialog-body">
        <div class="title" style="margin-top: 10px">
          积分 <span class="hightlight">+100</span>
        </div>
        <div class="message" style="margin-top: 10px">恭喜本周助威满20次</div>
        <div class="tips" style="margin-top: 10px">可到我的积分记录查询</div>
      </div>
    </ImperativeDialog>
  </div>
</template>

<style scoped lang="less">
.page {
  width: 100%;

  .notice_board {
    margin: 15px 0;
    padding: 0px 22px;
    box-sizing: border-box;
    position: relative;
    height: 41vw;
    overflow: hidden;

    .background {
      position: absolute;
      top: 0;
      left: 16px;
      width: calc(100vw - 32px);

      .background_image {
        width: 100%;
        height: 100%;
      }
    }

    .content {
      position: relative;
      z-index: 10;
      height: 29vw;
      width: calc(100% - 12px - 12px);
      overflow: auto;
      margin-top: 12px;
      margin-left: 12px;
      box-sizing: border-box;
      color: rgba(255, 255, 255, 0.9);

      .title {
        font-size: 18px;
        margin-bottom: 16px;
      }

      .rules {
        font-size: 12px;
        text-align: left;
        line-height: 1.4;
        list-style: decimal;
        padding-left: 20px;
        box-sizing: border-box;

        li {
          margin-bottom: 8px;
        }
      }
    }
  }

  .tab {
    margin-top: 24px;
    padding: 0px 16px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;

    position: relative;

    .tab_item {
      font-size: 16px;
      font-family: Tencent;
      color: rgba(171, 171, 171, 1);

      .line {
        width: 30px;
        height: 2px;
        border-radius: 2px 2px 2px 2px;
        background: rgb(240, 116, 91);
        margin: auto;
        margin-top: 4px;
        opacity: 0;
      }

      &__active {
        color: rgba(35, 35, 35, 1);

        .line {
          opacity: 1;
        }
      }
    }
  }

  .total {
    color: rgba(210, 210, 210, 1);
    font-size: 12px;
    text-align: left;
    padding: 0px 22px;
    box-sizing: border-box;
    margin-top: 16px;

    .count {
      color: rgba(240, 116, 91, 1);
    }
  }

  .items {
    padding: 0px 12px;
    box-sizing: border-box;

    .item {
      border-radius: 6px;
      background: #ffffff;
      box-shadow: 0 1px 5px 0 #ababab33;
      padding: 12px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      margin-top: 6px;
      align-items: flex-start;

      .item_picture {
        width: 40px;
        height: 40px;
        border-radius: 8px;
        margin-top: 8px;
        margin-top: 4px;
      }

      .item_info {
        margin-left: 12px;
        text-align: left;
        flex: 1 1;
        min-width: 0;

        .item_header {
          display: flex;
          align-items: center;

          .item_name {
            font-size: 16px;
            overflow: hidden;
            text-overflow: ellipsis; //文本溢出显示省略号
            white-space: nowrap; //文本不会换行
          }

          .item_current_encourage_value {
            color: #f08a5b;
            font-size: 12px;
            margin-left: auto;
          }
        }

        .item_footer {
          display: flex;
          align-items: flex-start;

          .item_disseminate {
            font-size: 14px;
            color: #9ba2a5;
            margin-top: 8px;
            overflow: hidden;
            flex: 1 1;
            word-break: break-all;

            .item_team_tag {
              height: 1.1em;
              translate: 0px 3px;
            }
          }

          .assist_button {
            height: 24px;
            margin-top: 4px;
            margin-left: 8px;

            img {
              height: 100%;
            }
          }
        }
      }
    }
  }

  .loading {
    color: #9ba2a5;
    line-height: 300px;
  }

  .nodata {
    margin-top: 40px;
  }

  padding-bottom: 60px;
}

.raffle_button {
  position: fixed;
  right: 10px;
  width: 54px;
  bottom: 140px;

  img {
    width: 100%;
  }
}

.dialog-body {
  display: flex;
  flex-direction: column;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;

  .message {
    color: #435057;
    font-weight: 400;
    font-size: 16px;
  }

  .title {
    font-size: 18px;
  }

  .hightlight {
    color: #ff6b49;
  }

  .tips {
    font-size: 13px;
    color: #b1b1b1;
  }
}
</style>
